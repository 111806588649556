.dashboard {
    min-height: 100vh;
    height: 100%;
    background-color: var(--grey);
}

.dashboard .content {
    width: 100%;
    height: 200px;
    background-color: var(--blue);
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 100px;
}

.dashboard .content div {
    width: 25%;
    height: 130px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 1px rgb(0, 0, 0);
    margin-bottom: -50px;
    display: flex;
    align-items: center;
}

.dashboard .content div .material-symbols-outlined {
    font-size: 80px;
    color: var(--blue);
}

.dashboard .content div span {
    font-weight: 600;
    font-size: 28px;
}

.dashboard .table {
    padding: 0 100px;
    margin-top: 180px;
    width: 100%;
    height: 100%;
}

.dashboard .table .insert {
    width: 100%;
    height: 70px;
    background-color: white;
    box-shadow: 0px 0px 1px rgb(0, 0, 0);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.dashboard .table .insert .text input {
    width: 48%;
    height: 45px;
    padding: 0 10px;
    outline: none;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 1px rgb(0, 0, 0);
}

.dashboard .table .insert .text {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.dashboard .table .insert .radio {
    width: 15%;
    display: flex;
    justify-content: space-between;
}

.dashboard .table .insert .radio label {
    margin-left: 5px;
}

.dashboard .table .insert button {
    width: 20%;
    height: 45px;
    background-color: var(--blue);
    border: none;
    border-radius: 50px;
    font-size: 14px;
    color: white;
    cursor: pointer;
}


.dashboard .table .insert button:hover {
    background-color: var(--bluehover);
}

.dashboard .table .insert button:focus {
    background-color: var(--bluefocus);
}

.dashboard .table table {
    width: 100%;
    height: 100px;
    background-color: white;
    box-shadow: 0px 0px 1px rgb(0, 0, 0);
    border-radius: 5px;
    margin-top: 15px;
    padding: 20px;
}

.dashboard .table table th {
    text-align: left;
    height: 30px !important;
}


.dashboard .table table tr:nth-child(1) {
    box-shadow: 0px 1px 0px rgb(201, 201, 201);
}