:root {
  --blue: #2194F3;
  --bluehover: #197dcf;
  --bluefocus: #1764a3;
  --grey: #f2f3ef;
}

body {
  margin: 0px;
  font-family: 'Maven Pro', sans-serif;
  overflow: hidden;
}


* {
  color: rgb(0 0 0 / 75%);
  box-sizing: border-box;
}