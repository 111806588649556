
.App {
  min-height: 100vh;
  background-color: var(--blue);
}

.icone {
  width: 50px;
  border-radius: 5px;
  margin: 20px;
  transition: 0.3s;
  cursor: pointer;
  z-index: 1;
  position: relative;
  box-shadow: 0px 0px 1px rgb(0, 0, 0);
}

.icone:hover {
  transform: scale(0.9);
}


.container {
  height: 70px;
  font-size: 70px;
  line-height: 70px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: 120px !important;
  margin: auto;
  overflow: hidden;
}

.container ul {
  list-style: none;
  padding-left: 10px;
  animation: web 7s infinite;

}

.container ul,
.container p {
  margin: 0;
  color: white;
}

.container li{
  color: white;
}

.modal {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--grey);
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.modal button {
  width: 100%;
  height: 50px;
  background-color: var(--blue);
  border: none;
  border-radius: 50px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  margin-bottom: 30px;
}

.modal button:hover {
  background-color: var(--bluehover);
}

.modal button:focus {
  background-color: var(--bluefocus);
}

.modal a {
  width: 70%;
}

@keyframes web {

  0% {
    margin-top: 0;
  }

  20% {
    margin-top: 0;
  }

  25% {
    margin-top: -70px;
  }

  50% {
    margin-top: -70px;
  }

  55% {
    margin-top: -140px;
  }

  80% {
    margin-top: -140px;
  }

  85% {
    margin-top: -70px;
  }

  95% {
    margin-top: -70px;
  }

  100% {
    margin-top: 0;
  }
}

@media(max-width: 1100px) {
  .container {
    margin-left: 50px !important;
  }
}

@media(max-width: 1100px) {
  .container {
    font-size: 50px;
  }
}

@media(max-width: 768px) {
  .modal {
    display: none;
  }

  .container {
    justify-content: center;
    margin-left: 0px !important;
    width: 100%;
  }
}